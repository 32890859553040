import styled from 'styled-components';
import { useEffect, useState } from 'react';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import StudentForm from './StudentForm';
import ClassForm from './ClassForm';
import TimeslotForm from './TimeslotForm';
import MasterStudentForm from './MasterStudentForm';

const Section = styled.section``;

const Container = styled.div`
  padding: 30px 0;
`;

// #region
// for 1 session (1 or multiple timeslot)
// const opts = {
//   fields: ['studentId', 'sessionType', 'verifiedBy', 'timeslot'],
// };
// const data = [
//   {
//     studentId: 'sg001',
//     sessionType: 'online',
//     verifiedBy: 'munmuntest',
//     timeslot: '9:30am - 12:30pm (Tampines)',
//   },
//   {
//     studentId: 'sg002',
//     sessionType: 'online',
//     verifiedBy: 'munmuntest',
//     timeslot: '9:30am - 12:30pm (Tampines)',
//   },
//   {
//     studentId: 'sg003',
//     sessionType: 'online',
//     verifiedBy: 'munmuntest',
//     timeslot: '9:30am - 12:30pm (Tampines)',
//   },
//   {
//     studentId: 'sg004',
//     sessionType: 'online',
//     verifiedBy: 'munmuntest',
//     timeslot: '9:30am - 12:30pm (Tampines)',
//   },
// ];
// for the whole session
// const opts = {};

// const data = [
//   {
//     name: 'aaa',
//     email: 'aaa',
//     studentId: 'sg001',
//     Jan_1_2023: '9:30am | Online (UNVERIFIED)',
//   },
//   {
//     name: 'bbb',
//     email: 'bbb',
//     studentId: 'sg002',
//     Jan_1_2023: '9:30am | Online',
//     Feb_2_2023: '9:30am | Online',
//   },
// ];
// #endregion

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  border-left: 1px solid ${$.color.gray};
  border-right: 1px solid ${$.color.gray};
  border-top: 1px solid ${$.color.gray};
  padding: 10px;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  bottom: -1px;
  z-index: 1;
  color: ${$.color.gray};
  background-color: ${$.color.white};
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.active {
    color: ${$.color.black};
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 13px;
  }
  // #endregion
`;

const FormContainer = styled.div`
  ${({ activeTab }) => `
  border: 1px solid ${$.color.gray};
  width: 100%;
  background-color: ${$.color.white};

  & > *:not(.${activeTab}) {
    display: none;
  }
`}
`;

const ImportantNotice = styled.div`
  background-color: ${$.color.orange1};
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
  margin-bottom: 30px;

  h3 {
    margin: 0;
    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }
`;

const tabs = Utils.addKeys([
  { value: 'master', text: 'Master Student' },
  {
    value: 'student',
    text: 'Student',
  },
  {
    value: 'class',
    text: 'Class',
  },
  {
    value: 'timeslot',
    text: 'Timeslot',
  },
]);

const InstructorReport = () => {
  const [selection, setSelection] = useState({
    date_id: '',
    time_slot_taken: '',
  });
  const [activeTab, setActiveTab] = useState('student');
  const [studentSearchTerm, setStudentSearchTerm] = useState('');

  useEffect(() => {
    switch (activeTab) {
      case 'student':
        setSelection({
          date_id: '',
          time_slot_taken: '',
        });
        break;
      case 'class':
        setStudentSearchTerm('');
        break;
      case 'timeslot':
        setStudentSearchTerm('');
        setSelection({
          date_id: '',
          time_slot_taken: '',
        });
        break;
      default:
        setStudentSearchTerm('');
        setSelection({
          date_id: '',
          time_slot_taken: '',
        });
        break;
    }
  }, [activeTab]);

  return (
    <Section>
      <Container>
        <ImportantNotice>
          <h3>Important Notice:</h3>
          <p>
            If the downloads are not working, please switch to a modern browser
            (E.g. Chrome, Firefox) and enable multiple downloads when prompted.
          </p>
        </ImportantNotice>
        <Tabs>
          {tabs.map(({ text, value, key }) => (
            <Title
              key={key}
              onClick={() => {
                setActiveTab(value);
              }}
              className={activeTab === value ? 'active' : ''}
            >
              {text}
            </Title>
          ))}
        </Tabs>
        <FormContainer activeTab={activeTab}>
          <MasterStudentForm />
          <StudentForm
            studentSearchTerm={studentSearchTerm}
            setStudentSearchTerm={setStudentSearchTerm}
          />
          <ClassForm selection={selection} setSelection={setSelection} />
          <TimeslotForm selection={selection} setSelection={setSelection} />
        </FormContainer>
      </Container>
    </Section>
  );
};

export default InstructorReport;
