import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { createCSVFile } from './helper';
import $ from '../../../styles/global';
import ExportIcon from '../../../assets/icons/export.svg';
import {
  getSessionsAndTimeslotsGQL,
  getStudentsPerTimeslotGQL,
} from '../../../apollo/queries';

const Container = styled.div`
  padding: 30px;
`;

const Question = styled.div`
  margin-right: 10px;

  & > span {
    font-size: 15px;
    margin-right: 10px;
    font-weight: bold;
  }

  & > select {
    border: 1px solid ${$.color.gray};
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    margin-bottom: 20px;
    margin-right: 0;
    & > span {
      display: inline-block;
      width: 80px;
    }

    & > select {
      width: 250px;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
    margin-right: 0;
    & > span {
      display: block;
      margin-bottom: 5px;
    }

    & > select {
      width: 100%;
    }
  }
  // #endregion
`;

const Questions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    & > ${Question} {
      &:first-child {
        & > select {
          max-width: 150px;
        }
      }
      &:nth-child(2) {
        & > select {
          width: 300px;
        }
      }
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    align-items: flex-start;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  // #endregion
`;

const DownloadReportButton = styled.button`
  ${({ disable }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${disable ? $.color.gray : $.color.blue5};
  pointer-events: ${disable ? 'none' : 'initial'};
  border-radius: 5px;
  padding: 4px 10px;
  color: ${$.color.white};
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border: 1px solid ${$.color.blue5};
    color: ${$.color.blue5};
    background-color: ${$.color.white};

    & > svg {
      fill: ${$.color.blue5};
    }
  }

  & > svg {
    margin-right: 6px;
    fill: ${$.color.white};
    transition: all 0.3s ease;
  }

  & > span {
    font-size: 14px;
  }
`}
`;

const LoadingDataMessage = styled.div`
  margin-bottom: 20px;
`;

const ClassForm = ({ selection, setSelection }) => {
  const [datesData, setDatesData] = useState({});
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [getSessionsAndTimeslots, { loading: loadingSessionsAndTimeslots }] =
    useLazyQuery(getSessionsAndTimeslotsGQL, {
      onCompleted: ({ getSessionsAndTimeslots: result }) => {
        setDatesData(result);
      },
    });
  const [getStudentsPerTimeslot, { loading: downloadingReport }] = useLazyQuery(
    getStudentsPerTimeslotGQL,
    {
      onCompleted: ({ getStudentsPerTimeslot: result }) => {
        const opts = {
          fields: [
            {
              label: 'ID',
              value: 'student_id',
            },
            {
              label: 'Name',
              value: 'name',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Timeslot',
              value: 'time_slot_taken',
            },
            {
              label: 'Session',
              value: 'class_status',
            },
            {
              label: 'Instructor',
              value: 'instructor_id',
            },
            {
              label: 'Verified',
              value: 'verified',
            },
          ],
        };
        const tst =
          selection.time_slot_taken === 'all'
            ? 'all'
            : datesData.timeslots
                .filter(
                  ({ timeslot_id: t }) => t === selection.time_slot_taken
                )[0]
                .timeslot.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, '_');

        const parsedStudentData = result.map(
          ({
            class_status: cs,
            instructor_id: iid,
            time_slot_taken: timeTaken,
            verified = false,
            student_id: sid,
            ...rest
          }) => ({
            class_status: cs.toUpperCase(),
            instructor_id: iid || '-',
            time_slot_taken: datesData.timeslots.filter(
              ({ timeslot_id: t }) => t === timeTaken
            )[0].timeslot,
            verified: verified ? 'TRUE' : 'FALSE',
            student_id: sid.toUpperCase(),
            ...rest,
          })
        ) || [
          {
            name: '',
            email: '',
            class_status: '',
            instructor_id: '',
            time_slot_taken: '',
            verified: '',
            student_id: '',
          },
        ];

        createCSVFile({
          data: parsedStudentData,
          opts,
          filename: `${selection.date_id}_${tst}_attendance_report`,
        });
      },
    }
  );

  useEffect(() => {
    getSessionsAndTimeslots();
  }, []);

  useEffect(() => {
    if (downloadingReport) {
      setDisableDownloadButton(true);
      return;
    }

    if (selection.time_slot_taken === '' || selection.date_id === '') {
      setDisableDownloadButton(true);
      return;
    }

    setDisableDownloadButton(false);
  }, [downloadingReport, selection]);

  return (
    <Container className="class">
      <Questions>
        <Question>
          <span>Date: </span>
          <select
            value={selection.date_id || ''}
            onChange={(e) => {
              setSelection(() => ({
                time_slot_taken: '',
                date_id: e.target.value,
              }));
            }}
          >
            <option value="">--Select a date--</option>
            {datesData?.sessions?.map(({ date_id: did }) => (
              <option value={did} key={`${did}_options`}>
                {did.replace(/_/g, '-')}
              </option>
            ))}
          </select>
        </Question>
        <Question>
          <span>Timeslot:</span>
          <select
            value={selection.time_slot_taken || ''}
            onChange={(e) => {
              setSelection((prev) => ({
                ...prev,
                time_slot_taken: e.target.value,
              }));
            }}
          >
            <option value="">--Select a timeslot--</option>
            {selection?.date_id &&
              datesData?.sessions
                ?.filter(({ date_id: did }) => did === selection.date_id)[0]
                .timeslots.map((timeslot, index, arr) => {
                  const timeslotText = datesData.timeslots.filter(
                    ({ timeslot_id: tid }) => tid === timeslot
                  )[0].timeslot;

                  return (
                    <>
                      <option
                        value={timeslot}
                        key={`${selection.date_id}_${timeslot}`}
                      >
                        {timeslotText}
                      </option>
                      {arr.length > 1 && index === arr.length - 1 && (
                        <option value="all" key={`${selection.date_id}_all`}>
                          All timeslots
                        </option>
                      )}
                    </>
                  );
                })}
          </select>
        </Question>
        <DownloadReportButton
          onClick={() => {
            if (selection.date_id && selection.time_slot_taken) {
              getStudentsPerTimeslot({ variables: { params: selection } });
            }
          }}
          disable={disableDownloadButton}
        >
          <ExportIcon />
          <span>
            {downloadingReport ? 'Downloading...' : 'Download Report'}
          </span>
        </DownloadReportButton>
      </Questions>
      {loadingSessionsAndTimeslots && (
        <LoadingDataMessage>Loading list of classes...</LoadingDataMessage>
      )}
    </Container>
  );
};

ClassForm.propTypes = {
  selection: PropTypes.shape({
    time_slot_taken: PropTypes.string,
    date_id: PropTypes.string,
  }),
  setSelection: PropTypes.func,
};

ClassForm.defaultProps = {
  selection: {
    time_slot_taken: '',
    date_id: '',
  },
  setSelection: () => {},
};

export default ClassForm;
