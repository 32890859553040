import { Parser } from '@json2csv/plainjs';

const createCSVFile = ({ data, opts, filename }) => {
  const downloadCSVFile = (csv) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${filename}.csv`);
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  try {
    const parser = new Parser(opts);
    const csv = parser.parse(data);

    downloadCSVFile(csv);
  } catch (err) {
    console.error(err);
  }
};

export default createCSVFile;

export { createCSVFile };
