import styled from 'styled-components';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useLazyQuery } from '@apollo/client';
import { createCSVFile } from './helper';

import {
  listStudentsGQL,
  getAttendanceReportGQL,
} from '../../../apollo/queries';
import $ from '../../../styles/global';
import SearchIcon from '../../../assets/icons/search.svg';
import ExportIcon from '../../../assets/icons/export.svg';

const Container = styled.div`
  padding: 30px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 30px;

  & > input {
    max-width: 300px;
    width: 100%;
    border: 1px solid ${$.color.gray};
    padding: 5px 10px 5px 30px;
    border-radius: 5px;
    outline: none;
    margin-right: 10px;
  }

  & > svg {
    position: absolute;
    left: 5px;
    fill: ${$.color.gray};
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.gray};
  width: 100%;

  thead {
    tr {
      background-color: ${$.color.gray};
      td {
        text-align: center;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-right: 1px solid ${$.color.black};
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${$.color.gray};

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 10px;
        font-size: 14px;
        &:first-child {
          text-align: center;
          font-style: italic;
        }
        &:nth-child(2) {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
`;

const DownloadReportButton = styled.button`
  ${({ disable }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${disable ? $.color.gray : $.color.blue5};
  pointer-events: ${disable ? 'none' : 'initial'};
  border-radius: 5px;
  padding: 5px 10px;
  color: ${$.color.white};
  transition: all 0.3s ease;
  margin: auto;
  min-width: 163.8px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${$.color.blue5};
    color: ${$.color.blue5};
    background-color: ${$.color.white};

    & > svg {
      fill: ${$.color.blue5};
    }
  }

  & > svg {
    margin-right: ${$.layout().margin5}px;
    fill: ${$.color.white};
    transition: all 0.3s ease;
  }

  & > span {
    font-size: 13px;
  }
`}
`;

const StudentForm = ({ studentSearchTerm, setStudentSearchTerm }) => {
  const [studentFormData, setStudentFormData] = useState({});
  const [studentDataLoadingMessage, setStudentDataLoadingMessage] =
    useState('');
  const [disableStudentDownloadButton, setDisableStudentDownloadButton] =
    useState({ state: false, downloading: '' });
  const [listStudents] = useLazyQuery(listStudentsGQL, {
    onCompleted: ({ listStudents: data }) => {
      setStudentDataLoadingMessage('Ready.');
      setStudentFormData({ search: data });
      setTimeout(() => {
        setStudentDataLoadingMessage('');
      }, 2000);
    },
    onError: () => {
      setStudentDataLoadingMessage('Error. Please try again.');
      setTimeout(() => {
        setStudentDataLoadingMessage('');
      }, 2000);
    },
  });
  const [downloadAttendanceReport] = useLazyQuery(getAttendanceReportGQL, {
    onCompleted: ({ getAttendanceReport: data }) => {
      const opts = {
        fields: [
          {
            label: 'Class',
            value: (row) =>
              row.name ? `Student Name: ${row.name}` : row.date_id,
          },
          {
            label: 'Timeslot',
            value: (row) =>
              row.email ? `Student Email: ${row.email}` : row.time_slot_taken,
          },
          {
            label: 'Hours',
            value: (row) =>
              row.student_id ? `Student ID: ${row.student_id}` : row.hours,
          },
          {
            label: 'Session',
            value: (row) =>
              row.total_hours_offline && row.total_hours_online
                ? `Total Hours Offline: ${row.total_hours_offline} | Total Hours Online: ${row.total_hours_online}`
                : row.class_status,
          },
          {
            label: 'Instructor',
            value: 'instructor_id',
          },
          {
            label: 'Verified',
            value: 'verified',
          },
        ],
      };
      const parsedStudentData = data.attendance
        .map(
          ({
            class_status: cs,
            date_id: did,
            instructor_id: iid,
            time_slot_taken: tst,
            verified = false,
            hours,
          }) => ({
            class_status: cs.toUpperCase(),
            date_id: did.replace(/_/g, '-'),
            instructor_id: iid || '-',
            time_slot_taken: tst,
            verified: verified ? 'TRUE' : 'FALSE',
            hours,
          })
        )
        .sort((prev, next) => {
          const prevDate = new Date(prev.date_id).getTime();
          const nextDate = new Date(next.date_id).getTime();

          if (prevDate > nextDate) {
            return 1;
          }

          if (prevDate < nextDate) {
            return -1;
          }

          return 0;
        }) || [
        {
          class_status: '',
          date_id: '',
          instructor_id: '',
          time_slot_taken: '',
          verified: '',
          hours: '',
        },
      ];

      if (
        parsedStudentData.length > 0 &&
        parsedStudentData[0].class_status !== ''
      ) {
        parsedStudentData.unshift({
          ...studentFormData.search.filter(
            ({ student_id: sid }) => sid === data.attendance[0].student_id
          )[0],
          total_hours_offline: [...data.attendance, ...data.hours]
            .filter(({ class_status: cs }) => cs === 'offline')
            .map(({ hours }) => hours)
            .reduce((accumulator, next) => accumulator + next, 0),
          total_hours_online: [...data.attendance, ...data.hours]
            .filter(({ class_status: cs }) => cs === 'online')
            .map(({ hours }) => hours)
            .reduce((accumulator, next) => accumulator + next, 0),
        });
      }

      if (data.hours && data.hours.length > 0) {
        parsedStudentData.push(
          { date_id: '' },
          { date_id: '--Extra Hours Logged--' }
        );
        data.hours.forEach((obj) => {
          parsedStudentData.push({
            date_id: obj.date_id.replace(/_/g, '-'),
            hours: obj.hours,
            class_status: obj.class_status.toUpperCase(),
          });
        });
      }

      setDisableStudentDownloadButton({ state: false, downloading: '' });
      createCSVFile({
        data: parsedStudentData,
        opts,
        filename: `${(
          parsedStudentData[0]?.student_id || ''
        ).toUpperCase()}_attendance_report`,
      });
    },
    onError: () => {
      setDisableStudentDownloadButton({ state: false, downloading: '' });
    },
  });

  // First time loading the form, we pull the necessary data to get started.
  useEffect(() => {
    setStudentDataLoadingMessage('Loading...');
    listStudents();
  }, []);

  return (
    <Container className="student">
      <SearchContainer>
        <SearchIcon />
        <input
          type="text"
          value={studentSearchTerm || ''}
          placeholder="Search student name/email/id"
          onChange={(e) => {
            setStudentSearchTerm(e.target.value);
          }}
        />
        <div>{studentDataLoadingMessage}</div>
      </SearchContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <td>No.</td>
              <td>Student ID</td>
              <td>Name</td>
              <td>Email</td>
              <td>Download CSV</td>
            </tr>
          </thead>
          <tbody>
            {studentSearchTerm &&
              studentFormData?.search
                ?.filter(({ student_id: sid, name, email }) => {
                  const st = studentSearchTerm.toLowerCase();

                  return (
                    sid.toLowerCase().indexOf(st) > -1 ||
                    name.toLowerCase().indexOf(st) > -1 ||
                    email.toLowerCase().indexOf(st) > -1
                  );
                })
                .map(({ student_id: sid, name, email }, index) => (
                  <tr key={`student_row_${sid}`}>
                    <td>{`${index + 1}.`}</td>
                    <td>{sid}</td>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>
                      <DownloadReportButton
                        disable={disableStudentDownloadButton.state}
                        onClick={() => {
                          setDisableStudentDownloadButton({
                            state: true,
                            downloading: sid,
                          });
                          downloadAttendanceReport({
                            variables: { student_id: sid },
                          });
                        }}
                      >
                        <ExportIcon />
                        <span>
                          {disableStudentDownloadButton.downloading === sid &&
                          disableStudentDownloadButton.state === true
                            ? 'Downloading...'
                            : 'Attendance Report'}
                        </span>
                      </DownloadReportButton>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

StudentForm.defaultProps = {
  studentSearchTerm: '',
  setStudentSearchTerm: () => {},
};

StudentForm.propTypes = {
  studentSearchTerm: PropTypes.string,
  setStudentSearchTerm: PropTypes.func,
};

export default StudentForm;
