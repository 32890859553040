import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { createCSVFile } from './helper';
import $ from '../../../styles/global';
import ExportIcon from '../../../assets/icons/export.svg';
import {
  getTimeslotsGQL,
  getTimeslotAttendanceGQL,
} from '../../../apollo/queries';

const Container = styled.div`
  padding: 30px;
`;

const Question = styled.div`
  margin-right: 10px;

  & > span {
    font-size: 15px;
    margin-right: 10px;
    font-weight: bold;
  }

  & > select {
    border: 1px solid ${$.color.gray};
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
  }

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    margin-bottom: 20px;
    margin-right: 0;
    & > span {
      display: inline-block;
      width: 80px;
    }

    & > select {
      width: 250px;
    }
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    margin-bottom: 20px;
    margin-right: 0;
    & > span {
      display: block;
      margin-bottom: 5px;
    }

    & > select {
      width: 100%;
    }
  }
  // #endregion
`;

const Questions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    & > ${Question} {
      &:first-child {
        & > select {
          max-width: 150px;
        }
      }
      &:nth-child(2) {
        & > select {
          width: 300px;
        }
      }
    }
  }
  // #endregion

  // NOTE: Tablet and above
  // #region
  @media ${$.device.tabletA} {
    flex-direction: column;
    align-items: flex-start;
  }
  // #endregion

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  // #endregion
`;

const DownloadReportButton = styled.button`
  ${({ disable }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${disable ? $.color.gray : $.color.blue5};
  pointer-events: ${disable ? 'none' : 'initial'};
  border-radius: 5px;
  padding: 4px 10px;
  color: ${$.color.white};
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    border: 1px solid ${$.color.blue5};
    color: ${$.color.blue5};
    background-color: ${$.color.white};

    & > svg {
      fill: ${$.color.blue5};
    }
  }

  & > svg {
    margin-right: 6px;
    fill: ${$.color.white};
    transition: all 0.3s ease;
  }

  & > span {
    font-size: 14px;
  }
`}
`;

const LoadingDataMessage = styled.div`
  margin-bottom: 20px;
`;

const TimeslotForm = ({ selection, setSelection }) => {
  const [timeslotsData, setTimeslotsData] = useState([]);
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [getTimeslots, { loading: loadingTimeslots }] = useLazyQuery(
    getTimeslotsGQL,
    {
      onCompleted: ({ getTimeslots: result }) => {
        setTimeslotsData(result);
      },
    }
  );
  const [getTimeslotAttendance, { loading: downloadingReport }] = useLazyQuery(
    getTimeslotAttendanceGQL,
    {
      onCompleted: ({ getTimeslotAttendance: result }) => {
        const classDates = {};
        const tst = timeslotsData
          .filter(
            ({ timeslot_id: tid }) => tid === selection.time_slot_taken
          )[0]
          .timeslot.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, '_');

        const parsedResult = result.map(
          ({ attendance, student_id: sid, name, email }, index) => {
            const obj = {
              student_id: sid.toUpperCase(),
              name,
              email,
              index: index + 1,
            };

            attendance.forEach(
              ({
                date_id: did,
                verified,
                instructor_id: iid,
                class_status: cs,
              }) => {
                const dateArr = did.split('_');

                obj[did] = `${cs.toUpperCase() || '?'} | Verified: ${
                  verified || '?'
                } | Instructor: ${iid || '?'}`;

                classDates[did] = {
                  label: `${dateArr[1]}-${dateArr[0]}-${dateArr[2]}  ${
                    dateArr[3] ? `(Extra Session)` : ''
                  }`,
                  value: did,
                };
              }
            );

            return obj;
          }
        );

        const opts = {
          fields: [
            {
              label: 'No.',
              value: 'index',
            },
            {
              label: 'ID',
              value: 'student_id',
            },
            {
              label: 'Name',
              value: 'name',
            },
            {
              label: 'Email',
              value: 'email',
            },
            ...Object.keys(classDates)
              .map((d) => classDates[d])
              .sort((prev, next) => {
                const prevDate = new Date(prev.label).getTime();
                const nextDate = new Date(next.label).getTime();

                if (prevDate < nextDate) {
                  return -1;
                }

                if (prevDate > nextDate) {
                  return 1;
                }

                return 0;
              }),
          ],
        };

        createCSVFile({
          data: parsedResult,
          opts,
          filename: `(${selection.time_slot_taken})_${tst}_attendance_report`,
        });
      },
    }
  );

  useEffect(() => {
    getTimeslots();
  }, []);

  useEffect(() => {
    if (downloadingReport) {
      setDisableDownloadButton(true);
      return;
    }

    if (selection.time_slot_taken === '') {
      setDisableDownloadButton(true);
      return;
    }

    setDisableDownloadButton(false);
  }, [downloadingReport, selection]);

  return (
    <Container className="timeslot">
      <Questions>
        <Question>
          <span>Timeslot:</span>
          <select
            value={selection.time_slot_taken || ''}
            onChange={(e) => {
              setSelection((prev) => ({
                ...prev,
                time_slot_taken: e.target.value,
              }));
            }}
          >
            <option value="">--Select a timeslot--</option>
            {timeslotsData
              .filter(
                ({ timeslot_id: tid }) =>
                  [
                    'TM1',
                    'TM2',
                    'TP1',
                    'TP2',
                    'TP3',
                    // Other days or visit days. Enable if needed because it is confusing without context in the dropdown.
                    'F2',
                    // 'Full1',
                    'T3',
                    'F1',
                    'SP1',
                    'SP2',
                    'SP3',
                    'AWS1',
                    'AWS2',
                    'AWS3',
                  ].indexOf(tid) > -1
              )
              .sort((prev) => {
                if (['SP2', 'SP3'].indexOf(prev.timeslot_id) > -1) {
                  return -1;
                }

                return 0;
              })
              .sort((prev) => {
                if (
                  ['TM1', 'TM2', 'TP1', 'TP2', 'TP3'].indexOf(
                    prev.timeslot_id
                  ) > -1
                ) {
                  return -1;
                }

                return 0;
              })

              .map(({ timeslot_id: tid, timeslot }) => (
                <option value={tid} key={`${selection.date_id}_${timeslot}`}>
                  {timeslot}
                </option>
              ))}
          </select>
        </Question>
        <DownloadReportButton
          onClick={() => {
            if (selection.time_slot_taken) {
              getTimeslotAttendance({
                variables: { time_slot_taken: selection.time_slot_taken },
              });
            }
          }}
          disable={disableDownloadButton}
        >
          <ExportIcon />
          <span>
            {downloadingReport ? 'Downloading...' : 'Download Report'}
          </span>
        </DownloadReportButton>
      </Questions>
      {loadingTimeslots && (
        <LoadingDataMessage>Loading list of timeslots...</LoadingDataMessage>
      )}
    </Container>
  );
};

TimeslotForm.propTypes = {
  selection: PropTypes.shape({
    time_slot_taken: PropTypes.string,
    date_id: PropTypes.string,
  }),
  setSelection: PropTypes.func,
};

TimeslotForm.defaultProps = {
  selection: {
    time_slot_taken: '',
    date_id: '',
  },
  setSelection: () => {},
};

export default TimeslotForm;
