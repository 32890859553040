import { useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';

import { createCSVFile } from './helper';
import $ from '../../../styles/global';
import ExportIcon from '../../../assets/icons/export.svg';
import { getMasterAttendanceReportGQL } from '../../../apollo/queries';

const Container = styled.div`
  padding: 30px;
`;

const DownloadReportButton = styled.button`
  ${({ disable }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${disable ? $.color.gray : $.color.blue5};
  pointer-events: ${disable ? 'none' : 'initial'};
  border-radius: 5px;
  padding: 5px 10px;
  color: ${$.color.white};
  transition: all 0.3s ease;
  margin: auto;
  min-width: 163.8px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${$.color.blue5};
    color: ${$.color.blue5};
    background-color: ${$.color.white};

    & > svg {
      fill: ${$.color.blue5};
    }
  }

  & > svg {
    margin-right: ${$.layout().margin5}px;
    fill: ${$.color.white};
    transition: all 0.3s ease;
  }

  & > span {
    font-size: 13px;
  }
`}
`;

const MasterStudentForm = () => {
  const [disableStudentDownloadButton, setDisableStudentDownloadButton] =
    useState(false);
  const [getMasterReport] = useLazyQuery(getMasterAttendanceReportGQL, {
    onCompleted: ({ getMasterAttendanceReport: data }) => {
      const dateFields = [];
      const parsedData = data.map(({ dates, ...rest }) => {
        const objDates = {};

        dates.forEach(({ date_id: dateId, hours }) => {
          const parsedDate = (() => {
            const arr = dateId.split('_');
            const dateStr = `${arr[1]} ${arr[0]} ${arr[2]}`;

            if (['Jun_20_2023_1', 'Jun_13_2023_1'].indexOf(dateId) > -1) {
              return `${dateStr} (Afternoon Session)`;
            }

            if (['Jun_20_2023', 'Jun_13_2023'].indexOf(dateId) > -1) {
              return `${dateStr}  (Morning Session)`;
            }

            if (dateId.includes('EX')) {
              return `${dateStr}  (Extra Hours)`;
            }

            return dateStr;
          })();

          if (dateFields.indexOf(parsedDate) === -1) {
            dateFields.push(parsedDate);
          }

          objDates[parsedDate] = hours;
        });

        return {
          ...rest,
          ...objDates,
        };
      });
      const opts = {
        fields: [
          {
            label: 'Student Name',
            value: (row) => row.name,
          },
          {
            label: 'Student ID',
            value: (row) => row.student_id,
          },
          {
            label: 'Student Email',
            value: (row) => row.email,
          },
          {
            label: 'Total Hours',
            value: (row) => row.total_hours,
          },
          ...dateFields
            .sort((curr, next) => {
              const currDate = new Date(
                curr
                  .replace(' (Extra Hours)', '')
                  .replace(' (Morning Session)', '')
                  .replace(' (Afternoon Session)', '')
              ).getTime();
              const nextDate = new Date(
                next
                  .replace(' (Extra Hours)', '')
                  .replace(' (Morning Session)', '')
                  .replace(' (Afternoon Session)', '')
              ).getTime();

              if (currDate > nextDate) {
                return 1;
              }

              if (currDate < nextDate) {
                return -1;
              }

              return 0;
            })
            .map((dateField) => ({
              label: dateField,
              value: (row) => row[dateField] || '-',
            })),
        ],
      };

      setDisableStudentDownloadButton(false);
      createCSVFile({
        data: parsedData,
        opts,
        filename: 'master_attendance_report',
      });
    },
    onError: () => {
      setDisableStudentDownloadButton(false);
    },
  });

  return (
    <Container className="master">
      <DownloadReportButton
        disable={disableStudentDownloadButton}
        onClick={() => {
          setDisableStudentDownloadButton(true);
          getMasterReport();
        }}
      >
        <ExportIcon />
        <span>
          {disableStudentDownloadButton === true
            ? 'Downloading...'
            : 'Download Report'}
        </span>
      </DownloadReportButton>
    </Container>
  );
};

export default MasterStudentForm;
